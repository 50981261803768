.form {
  max-width: 400px;
  background-color: white;
  padding: 30px;
  text-align: left;
  max-width: 400px;
  background: rgb(255, 255, 255);
  // box-shadow: rgb(0 0 0 / 10%) 0px 2px 30px;
  // padding: 10px 30px 51px;
  border-radius: 5px;
  // text-align: lef;
  // margin-left: auto;
  // margin-right: auto;

  &__inner {
    display: flex;
    flex-flow: column;
  }

  &__group {
    display: flex;
    width: 100%;
    flex-flow: column;
    margin-top: 14px;
  }

  &__title {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 700;
    margin-bottom: 5px;
  }

  &__subtitle {
    font-size: 15px;
    color: rgb(105, 105, 105);
    margin-bottom: 1rem;
    text-align: center;
  }

  &__label {
    min-width: 30%;
  }

  &__error {
    color: red;
    margin-left: 10px;
    margin-top: 8px;
    font-size: 0.9rem;

    &--panel {
      color: white;
      background: red;
      padding: 0.5rem;
      margin-bottom: 0.4rem;
      border-radius: 10px;
      line-height: 1.5;
      font-size: 0.95rem;
      text-align: center;
    }
  }

  &__control {
    padding: 0 20px;
    min-height: 50px;
    background-color: #f8f8f8;
    color: rgb(105, 105, 105);
    background-color: rgb(248, 248, 248);
    font-size: 15px;
    font-weight: 400;
    border-width: 1px;
    border-color: rgb(248, 248, 248);
    border-style: solid;
    border-radius: 5px;
    color: rgb(105, 105, 105) !important;
    margin-top: 12px;
  }

  &__submit {
    @extend .button;
    border: none;
    border-radius: 5px;
    background-color: $color-pink;
    padding: 12px 56px 12px 56px;
    font-weight: 500;
    margin-top: 32px;
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    &:hover {
      background-color: $color-pink-lighter;
    }
  }
}
